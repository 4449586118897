body {
  margin: 0;
  background: #2155BF;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background: #2155BF;
  height:100vh;
  width:100vw;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:20px;
  padding-right:0px;
  box-sizing: border-box;
  gap:10px;
  color:white;
}
